import { CDN_PSPDFKIT_BASE_URL } from './constants';

/* Preloading pspdfkit dependencies: start */
export const PSPDFKIT_PREFETCH_LINKS = [
  'chunk-standalone-4f54e4cff41ba279.js',
  'chunk-locale-en-GB-e760e15d7f53b988.js',
  'chunk-locale-en-be713de2e89e142b.js',
  'chunk-localedata-en-862c7d2ed9a5ad1d.js',
  'pspdfkit-2024.3.2.css',
  'dark-3708e5109bf0f980.css',
  'pspdfkit-d65f8c86d3c3e05c.wasm',
  'pspdfkit-a40f15a37f0211b1.wasm.js'
];

export const PSPDFKIT_CUSTOM_PRELOAD_LINKS = PSPDFKIT_PREFETCH_LINKS.filter(fileName => fileName.includes('.wasm'));

/* Preloading pspdfkit dependencies: end */

export const getPSPDFKitAssetUrl = (fileName: string) => `${CDN_PSPDFKIT_BASE_URL}pspdfkit-lib/${fileName}`;

export const prefetchPSPDFKitWasmFiles = () => {
  PSPDFKIT_CUSTOM_PRELOAD_LINKS.forEach(asset => fetch(getPSPDFKitAssetUrl(asset)));
};
