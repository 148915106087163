import React from 'react';

import { Spinner } from 'assets/images';

interface ButtonProps {
  className?: string;
  color?: 'primary' | 'outlined' | 'alternate' | 'default' | 'red' | 'green' | 'green-outlined' | 'yellow' | 'unset' | 'link';
  disabled?: boolean;
  full?: boolean;
  isLoading?: boolean;
  size?: 'xs' | 'sm' | 'default' | 'lg' | 'xl';
  title?: string;
}

const Button = ({
  children,
  className = '',
  color = 'primary',
  disabled = false,
  full = false,
  isLoading = false,
  size = 'default',
  title,
  // ESLint: 'type' is missing in props validation
  // eslint-disable-next-line react/prop-types
  type = 'button',
  ...buttonProps
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  let bgColor: string;
  let bgHoverColor: string;
  let borderFocusColor: string;
  let colorName: string;
  let textColor: string;

  switch (color) {
    case 'primary':
      colorName = 'mauve1';
      bgColor = 'bg-mauve1';
      bgHoverColor = 'bg-mauve0';
      borderFocusColor = 'border-mauve0';
      textColor = 'text-white';
      break;

    case 'alternate':
      colorName = 'mauve1';
      bgColor = 'bg-transparent';
      bgHoverColor = 'bg-white';
      borderFocusColor = 'border-mauve0';
      textColor = 'text-mauve1';
      break;

    case 'outlined':
      colorName = 'glass-500';
      bgColor = 'bg-transparent';
      bgHoverColor = 'bg-white';
      borderFocusColor = 'border-glass-300';
      textColor = 'text-glass-500';
      break;

    case 'red':
      colorName = 'red';
      bgColor = 'bg-red-600';
      bgHoverColor = 'bg-red-500';
      borderFocusColor = 'border-red-700';
      textColor = 'text-white';
      break;

    case 'green':
      colorName = 'green';
      bgColor = 'bg-green-400';
      bgHoverColor = 'bg-green-300';
      borderFocusColor = 'border-green-600';
      textColor = 'text-white';
      break;

    case 'green-outlined':
      colorName = 'green';
      bgColor = 'bg-green-210';
      bgHoverColor = 'bg-green-300';
      borderFocusColor = 'border-green-600';
      textColor = 'text-green-400';
      break;

    case 'yellow':
      colorName = 'yellow';
      bgColor = 'bg-yellow-400';
      bgHoverColor = 'bg-yellow-100';
      borderFocusColor = 'border-yellow-600';
      textColor = 'text-black';
      break;

    case 'default':
      colorName = 'gray';
      textColor = 'text-gray-700';
      bgColor = 'bg-gray-200';
      bgHoverColor = 'bg-gray-300';
      borderFocusColor = 'border-gray-700';
      break;

    case 'unset':
      colorName = 'gray';
      textColor = 'text-gray-700';
      bgColor = 'bg-white';
      bgHoverColor = 'bg-gray-100';
      borderFocusColor = 'border-blue-300';
      break;

    case 'link':
      colorName = 'transparent';
      textColor = 'text-blue-500';
      bgColor = 'bg-transparent';
      bgHoverColor = 'bg-transparent';
      borderFocusColor = 'border-transparent';
    // ESLint: Expected a 'break' statement before 'default'
    // eslint-disable-next-line no-fallthrough
    default:
      break;
  }

  let spacing = 'px-4 py-2';

  switch (size) {
    case 'default':
      spacing = 'px-4 py-2 min-w-24';
      break;

    case 'xs':
      spacing = 'px-2 py-1';
      break;

    case 'lg':
      spacing = 'px-2 py-3';
      break;

    default:
      break;
  }

  let bgOpacity = '';

  if (disabled) bgOpacity = 'bg-opacity-50 cursor-not-allowed';

  const Children = (
    <>
      {title}
      {children}
    </>
  );

  return (
    <button
      className={`
        ${full ? 'w-full' : ''}
        ${color !== 'green-outlined' && color !== 'link' ? 'border shadow-sm' : ''}
        flex justify-center
        rounded-md text-sm transition duration-150
        ease-in-out hover:shadow-none focus:outline-none
        
        ${
          // @ts-expect-error TS(2454): Variable 'textColor' is used before being assigned... Remove this comment to see the full error message
          textColor
        }
        ${
          // @ts-expect-error TS(2454): Variable 'bgColor' is used before being assigned.
          bgColor
        }
        hover:${
          // @ts-expect-error TS(2454): Variable 'bgHoverColor' is used before being assig... Remove this comment to see the full error message
          bgHoverColor
        }
        focus:${
          // @ts-expect-error TS(2454): Variable 'borderFocusColor' is used before being a... Remove this comment to see the full error message
          borderFocusColor
        }
        focus:shadow-outline-${
          // @ts-expect-error TS(2454): Variable 'colorName' is used before being assigned... Remove this comment to see the full error message
          colorName
        }
        active:${
          // @ts-expect-error TS(2454): Variable 'borderFocusColor' is used before being a... Remove this comment to see the full error message
          borderFocusColor
        }

        ${spacing}
        ${bgOpacity}

        ${className}
      `}
      disabled={disabled}
      type={type}
      {...buttonProps}
    >
      {isLoading && <Spinner width="20" fill={color === 'unset' ? 'black' : 'white'} className="absolute animate-spin" />}
      <span className={isLoading ? 'invisible' : ''}>{Children}</span>
    </button>
  );
};

export default Button;
