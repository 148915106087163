import Head from 'next/head';
import React, { useEffect } from 'react';

import { createNonPersistentStore } from 'lib/zustand';

interface Props {
  title?: string;
}

type HeadStoreState = {
  title: string;
};

// We use a global Zustand store here because next/head title can only be rendered once and it will prefer the one closer to the DOM root.
// This is useful for cases like Instant Listening where we want to update the title of the page based on the current item, without triggering reload/navigation.
const useHeadStore = createNonPersistentStore<HeadStoreState>(
  () => ({
    title: 'Listen to text with Speechify'
  }),
  {
    isListeningScreenStore: false
  }
);

const HeadComponent: React.FC<Props> = ({ title = 'Listen to text with Speechify' }) => {
  const headStoreState = useHeadStore();

  useEffect(() => {
    const previousTitle = useHeadStore.getState().title;
    useHeadStore.setState({ title });
    // We need to restore original title because we can have multiple <HeadComponent/> rendered at once, so we need to cleanup after unmounting the nested one.
    return () => useHeadStore.setState({ title: previousTitle });
  }, [title]);

  return (
    <Head>
      <title>{headStoreState.title} | Speechify</title>
    </Head>
  );
};

export default HeadComponent;
