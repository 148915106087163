import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { isFunction } from 'lodash';
import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import { useTranslation } from 'hooks/useTypedTranslation';

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, React.TextareaHTMLAttributes<HTMLTextAreaElement> & { error?: { message?: string } }>(
  // ESLint: 'name' is missing in props validation & 'className' is missing in props validation
  // eslint-disable-next-line react/prop-types, react/prop-types
  ({ error, name, className, ...rest }, ref) => {
    return (
      <Fragment>
        <textarea
          {...rest}
          className={
            'focus:shadow-outline-blue block h-40 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition duration-150 ease-in-out focus:border-blue-400 focus:outline-none sm:text-sm sm:leading-5 ' +
            className
          }
          name={name}
          ref={ref}
        />
        {error && error.message && <div className="py-2 text-red-400">{error.message.charAt(0).toUpperCase() + error.message.slice(1)}</div>}
      </Fragment>
    );
  }
);
TextAreaInput.displayName = 'TextAreaInput';

export const SelectInput = React.forwardRef(
  // ESLint: 'name' is defined but never used & 'ref' is defined but never used & Unexpected any & Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
  ({ selected, setSelected, className, error, name, inputLabel, options = [], placeholderText = 'Select', noOptionsText = 'No Options' }: any, ref: any) => {
    // ESLint: 't' is assigned a value but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation('common');
    const hasOptions = options.length > 0;
    return (
      <Fragment>
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <Fragment>
              {inputLabel && <Listbox.Label className="block text-sm font-medium text-gray-700">{inputLabel}</Listbox.Label>}
              <div className="relative">
                <Listbox.Button
                  className={twMerge(
                    'relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm',
                    className
                  )}
                >
                  <span className={twMerge(selected ? '' : 'text-gray-400', 'block truncate')}>
                    {selected || (hasOptions ? placeholderText : noOptionsText)}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition show={open && hasOptions} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {/* ESLint: 'name' is defined but never used & Unexpected any */}
                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */}
                    {options.map(({ label, name, value }: any) => (
                      <Listbox.Option
                        key={`${label}-${value}`}
                        className={({ active }) =>
                          twMerge(active ? 'bg-speechify-blue-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')
                        }
                        value={value}
                      >
                        {({ selected, active }) => (
                          <Fragment>
                            <span className={twMerge(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{isFunction(label) ? label() : label}</span>

                            {selected ? (
                              <span className={twMerge(active ? 'text-white' : 'text-speechify-blue-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </Fragment>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Fragment>
          )}
        </Listbox>

        {error && error.message && (
          <Fragment>
            <div style={{ flexBasis: '100%', height: 0 }}></div>
            <div className="flex-none py-2 text-red-400">{error.message.charAt(0).toUpperCase() + error.message.slice(1)}</div>
          </Fragment>
        )}
      </Fragment>
    );
  }
);
SelectInput.displayName = 'SelectInput';

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
export const TextInput = React.forwardRef(({ className, error, name, type = 'text', ...rest }: any, ref: any) => {
  return (
    <Fragment>
      <input
        {...rest}
        className={twMerge(
          className,
          'focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition duration-150 ease-in-out focus:border-blue-400 focus:outline-none sm:text-sm sm:leading-5'
        )}
        name={name}
        ref={ref}
        type={type}
      />

      {error && error.message && (
        <Fragment>
          <div style={{ flexBasis: '100%', height: 0 }}></div>
          <div className="flex-none py-2 text-red-400">{error.message.charAt(0).toUpperCase() + error.message.slice(1)}</div>
        </Fragment>
      )}
    </Fragment>
  );
});
TextInput.displayName = 'TextInput';

export default TextInput;
